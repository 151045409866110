import { styled } from 'Theme/stitches.config';
import MagazineListingBlockModel from 'Models/Blocks/MagazineListingBlock/MagazineListingBlockModel.interface';
import Grid from 'DesignSystem/Grids/Grid';
import MagazineCard from 'Commerce/Molecules/MagazineCard/MagazineCard';
import LoadMoreContainer from 'DesignComponents/Molecules/LoadMoreContainer/LoadMoreContainer';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import BlockHeader from './BlockHeader';
import MagazineCardModel from 'Models/MagazineCard/MagazineCardModel.interface';
import useLoadMore from 'Shared/Hooks/useLoadMore';

type PropType = {
  content: MagazineListingBlockModel;
};

function MagazineListingBlock({ content }: PropType) {
  const {
    heading,
    inEditMode,
    magazineCards,
    pageSize,
    showHorizontalScrollOnMobile,
    showPriceFrom,
  } = content;

  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const { categoryLabels } = useTranslationData();
  const { list, showMore, loadMore } = useLoadMore<MagazineCardModel>(
    magazineCards,
    isMobile && showHorizontalScrollOnMobile ? 0 : pageSize
  );

  if (magazineCards.length <= 0) return <></>;
  return (
    <Root>
      <BlockHeader inEditMode={inEditMode} heading={heading} />
      <Grid
        noGutter
        showScrollbar={isMobile && showHorizontalScrollOnMobile}
        css={
          isMobile && showHorizontalScrollOnMobile
            ? {
                '@bpMax720': {
                  display: 'flex',
                  flex: '1 1 0px',
                },
              }
            : { gridGap: '$s75', '@bpMin481': { gridGap: '$s200' } }
        }
      >
        {list.map((magazine, index) => (
          <GridItem key={index}>
            <MagazineCard
              showHorizontalScrollOnMobile={
                isMobile && showHorizontalScrollOnMobile
                  ? showHorizontalScrollOnMobile
                  : undefined
              }
              item={magazine}
              inEditMode={inEditMode}
              css={
                isMobile && showHorizontalScrollOnMobile
                  ? {}
                  : { width: 'auto' }
              }
              showPriceInfo={showPriceFrom}
            />
          </GridItem>
        ))}
      </Grid>
      {showMore && (
        <LoadMoreWrapper>
          <LoadMoreContainer
            loadMore={categoryLabels.loadMore}
            isLoading={false}
            onClick={loadMore}
          />
        </LoadMoreWrapper>
      )}
    </Root>
  );
}

const Root = styled('section', {
  mx: 'auto',
  my: 0,
  px: 0,
  wh: '100%',
  py: '$s200',
  '@bpMin721': {
    py: '$s300',
  },
});

const GridItem = styled('div', {
  display: 'grid',
  gridColumnEnd: 'span 2',
});

const LoadMoreWrapper = styled('div', {});

export default MagazineListingBlock;
